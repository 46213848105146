@import './../../scss/all.scss';

.job {
    // background-color: $white;
    // background-image: linear-gradient(to bottom, #cceaec, #daeef6, #e9f3fb, #f6f8fd, #ffffff);
    background-color: $color-7;
    background-position: top;
    width: 100%;
    overflow: hidden;
    height: auto;
    padding: 40px 0  40px;
    position: relative;


    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    &.animate {
        h2, .job__tab {
            @include fade("in","right", 200px, 4s);
        }

        .job__list__item {
            @include fade("in","right", 200px, 4s);

        }
    }

    h2 {
        text-align: center;
        opacity: 0;

        @include tablet {
            font-size: 62px;
        }

        @include mobile {
            font-size: 42px;
        }
    }

    .content {
        position: relative;
        flex-direction: column;
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .job__tab {
        padding: 15px;
        margin-bottom: 20px;
        opacity: 0;

        &__list {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
        }
        
        &__item {
            margin: 0 10px;

            @include mobile {
                width: 100%;   
            }
            
            p {
                border:  1px solid $color-1;
                cursor: pointer;
                font-size: 18px;
                color: $color-1;
                padding: 8px 18px;
                transition: 650ms all cubic-bezier(0.24, 0.98, 0.38, 0.99);
                border-radius: 8px;

                &:hover {
                    background-color: $color-1;
                    color: $white;
                }
            }
            
            .active {
                background-color: $color-3;
                color: $white;
            }

        }
    }


    .job__list {
        display: grid;
        grid-template-columns: auto auto auto;
        max-width: 1220px;
        margin: 0 auto;
        gap: 20px;
        padding-bottom: 100px;
        
        @include tablet {
            grid-template-columns: auto auto;
        }

        @include mobile {
            padding: 38px 0 60px;
        }

        &__item {
            width: 100%;
            border-radius: 16px;
            position: relative;
            display: flex;
            max-width: 400px;
            opacity: 0;

            box-shadow: 20px 20px 60px #a3a3a3, -10px -10px 20px #ffffff;

            img {
                max-width: 400px;
                width: 100%;
                height: auto;
                border-radius: 16px;
            }

            &__box {
                cursor: pointer;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                transition: 650ms all cubic-bezier(0.24, 0.98, 0.38, 0.99);
                display: flex;
                align-items: center;
                flex-direction: column;
                border-radius: 16px;

                @include mobile {
                    display: none;
                }


                &:hover {
                    background-color: rgba($color: $color-2-400, $alpha: .8);

                    p {
                        opacity: 1;
                        top: 20%;
                    }

                    a {
                        opacity: 1;
                        bottom: 20%;
                    }
                }
                
                p {
                    cursor: default;
                    color: $white;
                    position: absolute;
                    top: 0%;
                    opacity: 0;
                    transition: 600ms all cubic-bezier(0.24, 0.98, 0.38, 0.99);
                    
                    display: flex;
                    flex-direction: column;
                }

                    .box__name {
                        font-size: 28px;
                        font-weight: 800;
                        margin-bottom: 5px;
                    }

                a {
                    cursor: pointer;
                    color: $white;
                    font-size: 18px;
                    font-weight: 800;
                    position: absolute;
                    bottom: 0%;
                    opacity: 0;
                    transition: 600ms all cubic-bezier(0.24, 0.98, 0.38, 0.99);
                    border: 1px solid $white;
                    padding: 10px 30px;

                    &:hover {
                        background-color: $white;
                        color: $text-color-pure;
                    }
                }
            }
        }
    }
}
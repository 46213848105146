@import "./tokens/all";
@import "./mixins/all";
@import "./extends/all";
@import "./placeholders/all";

* {
    font-family: $main-font;
}

body {
    scroll-behavior: smooth
}

.default-title {
    color: $color-1;
    font-weight: 700;
    font-size: 46px;
    margin: 0 0 30px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;

    @include desktop {
        font-size: 32px;
    }
}

// html,body {
//     overflow: hidden;
//     width: 100vw;
//     height: 100vh;
// }

// #root {
//     overflow: hidden;
//     width: 100%;
//     height: 100%;
// }

// .App {
//     overflow-y: scroll;
//     width: 100%;
//     height: 100%;
//     scroll-snap-type: y mandatory;
//     scroll-snap-align: center;
// }

// section {
//     scroll-snap-align: start;
// }

.container {
    @extend %container;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.container-fluid {
    @extend %container-fluid;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.primary-button {
    height: 100%;
    width: auto;
    padding: 20px 40px;
    border-radius: 50px;
    color: $white;
    font-size: $font-size-lg;
    font-weight: $font-weight-700;
    background-color: $color-1;
    transition: 200ms all ease-in-out;

    &:hover {
        background-color: $color-1-700;
    }

    @include tablet {
        padding: 12px 16px;
        font-size: 14px;
    }
}

.secondary-button {
    height: 100%;
    width: auto;
    padding: 20px 40px;
    border-radius: 50px;
    border: 1px solid $color-1;
    color: $color-1;
    font-size: $font-size-lg;
    font-weight: $font-weight-700;
    background-color: transparent;
    transition: 200ms all ease-in-out;

    &:hover {
        background-color: $color-1-500;
        color: $white;
    }

    @include tablet {
        padding: 12px 16px;
        font-size: 14px;
    }
}
@import './../../scss/all.scss';

.header {
    background-color: rgba($color: $color-5, $alpha: 0);
    height: 101px;
    padding: 20px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: 400ms all ease-in-out;
    background-color: $white;

    @include tablet {
        height: 50px;
    }

    .container-fluid {
        gap: 16px;
    }

    &.fixed {
        // box-shadow: 0 4px 6px 0 rgba(12, 0, 46, 0.06);

    }
    
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }


    &__logo {
        img {
            max-height: 80px;
            width: auto;

            @include mobile {
                max-height: 40px;
            }
        }
        a {
            font-size: $font-size-xxl;
            text-transform: uppercase;
            color: $color-2;
            font-weight: $font-weight-700;
            transition: 400ms all ease-in-out;

            span {
                color: $color-1;
            }
        }
    }

        .highlight {
            background-color: rgba($color-1, .6);
            display: flex;
            border-radius: 4px;
            
            &:hover {
                background-color: $color-1;
                
                a {
                    color: $text-color-pure;
                }
            }
            
            a {
                color: $text-color-pure;
                &::after {
                    display: none;
                }
            }
        }
    

    &__menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 20px;


        @include tablet {
            gap: 2px;
        }

        @include mobile {
            display: none;
            opacity: 0;
            position: fixed;
            left: 0;
            top: 50px;
            width: 100%;
            height: auto;
            background-color: $white;

            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 14px;

            li {
                display: flex;
                width: 100%;
            }

            a {
                padding: 8px;
                width: 100%;
            }
        }

        
        a {
            padding: 6px 12px;
            color: $text-color-pure;
            font-weight: $font-weight-600;
            font-size: $font-size-md;
            transition: 200ms all ease-in-out;
            position: relative;

            @include desktop {
                font-size: 14px;
            }
            
            &:hover {
                color: $color-2;

                &::after {
                    // height: 2px;
                    // width: calc(100% - 24px);
                    transform: scale3d(1,1,1);
                    transform-origin: 0 50%;
                }
            }

            @include tablet {
                font-size: $font-size-sm;
            }
    
            &::after {
                content: "";
                width: calc(100% - 24px);
                height: 2px;
                background-color: $color-2;
                border-radius: 2px;
                position: absolute;
                bottom: -2px;
                left: 12px;
                opacity: 1;
                transition: transform .35s cubic-bezier(.2,1,.3,1);
                transform: scale3d(0,1,1);
                transform-origin: 100% 50%;

            }
        }

        &__button {
            display: none;
            width: 30px;
            height: auto;
            
            @include  tablet {
                display: block;
            }
            
            img {
                width: 30px;

            }
        }
    }


    &__button {
        @include desktop {
            font-size: 14px !important;
            padding: 10px 20px !important;
            text-align: center !important;
        }
        
        @include mobile {
            padding: 7px 10px !important;
            max-height: 70%;
            font-size: 12px;
            width: auto !important;
        }
    }

    &.open {
        .header__menu {
            display: flex;
            @include fade("in","down", 40px, .6s);
        }
    }
}
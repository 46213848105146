@import './../../scss/all.scss';

.statistics {
    background-color: $color-8;
    // min-height: 300px;
    padding: 30px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    overflow: hidden;

    .default-title {
        // opacity: 0;
    }

    .container {
        gap: 20px;
        align-items: flex-start;

        @include tablet {
        }
    }

    &__highlight {
        background-color: $color-8;
        width: 100%;
        padding: 40px 0 0 0;

        p {
            color: $white;
            font-size: 22px;
            line-height: 28px;
            width: 100%;
            text-align: center;

            span {
                color: $color-1;
                font-family: $main-font-3;
                font-weight: 700;
                letter-spacing: 1px;
            }

            b {
                color: $color-2;
                font-family: $main-font-3;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }
    }

    &__card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        max-width: 25%;
        width: 250px;
        height: 100%;
        // opacity: 0;

        @include tablet {
            max-width: 25%;
        }

        p {
            height: 41px;
            color: $color-7;
            font-size: 22px;
            font-weight: 500;
            text-align: center;

            @include tablet {
                font-size: 14px;
            }
        }
        img {
            max-width: 100%
        }
    }



    &.animate {
        .statistics__card-1 {
            @include fade("in","right", 300px, 4s,.8s);
        }
        .statistics__card-2 {
            @include fade("in","right", 300px, 4s,.6s);
        }
        .statistics__card-3 {
            @include fade("in","right", 300px, 4s,.4s);
        }
        .statistics__card-4 {
            @include fade("in","right", 300px, 4s,.2s);
        }
    }

}
@import './../../scss/all.scss';

.banner {
    background-image: $gradient-1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    &__link {
        margin-top: -3px;
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        background-color: $white;
        padding: 16px;
        gap: 20px;

        @include mobile {
            flex-direction: column;
        }

        p {
            display: flex;
            text-align: center;
            font-weight: 400;
            font-size: 20px;
            display: flex;
            gap: 20px;

            @include mobile {
                flex-direction: column;

                b {
                    font-size: 24px;
                }
            }

            b {
                font-weight: 700;
            }
        }

        a { 
            border-radius: 50px;
            padding: 10px;
            background-color: #48c55b;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            white-space: nowrap;
            gap: 10px;
            font-weight: 700;
            color: $white;
        }


        img {
            width: 40px;
            height: auto;
        }
    }

    @include tablet {
        height: auto;
    }

    .slick-slider {
        height: 100%    ;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        height: 100%;
    }

    .slick-slide {
        div {
            height: 100%;

            img {
                width: 100%;
            }
        }
    }

    .slick-prev {
        left: 50px !important;
        z-index: 20;
        transform: scale(2);
    }

    .slick-next {
        right: 50px !important;
        z-index: 20;
        transform: scale(2);
    }

    .slick-arrow {
        &::before,&::after {
            color: $color-1;
        }

        .slick-next {
            @include tablet {
                right: 0;
            }
        }

        .slick-prev {
            @include tablet {
                left: 0;
            }
        }
    }
    
    &__image {
        max-width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        
    }

    &__tree {
        width: 105vw;
        position: absolute;
        top: 0;
    }

    &__text {
        @include fade("in","right", 200px, 4s);
        position: absolute;
        top: 45%;
        left: 5%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;

        max-width: 90vw;

        @include tablet {
            top: 20%;
        }


        h2 {
            color: $text-color-pure;
            font-size: 62px;
            line-height: 70px;
            font-weight: $font-weight-700;

            @include tablet {
                line-height: 48px;
                font-size: 50px;
                text-align: left;
            }
            
            @include mobile {
                line-height: 28px;
                font-size: 28px;
            }
        }

        p {
            color: $text-color-pure;
            font-size: 32px;
            line-height: 40px;
            font-weight: $font-weight-500;
            @include tablet {
                font-size: 20px;
                line-height: 26px;
                text-align: left;
            }
            @include mobile {
                line-height: 16px;
                font-size: 16px;
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-top: 10px;


            .primary-button {
                box-shadow: 0px 20px 50px 0px #77e0f187;

                &:hover {
                    box-shadow: 0px 6px 10px 0px #77e0f187;
                }
            }
        }

        &__arrow {
            position: absolute;
            top: 100%;
            left: 0;
            max-height: 0px;
            transition: 300ms all cubic-bezier(0.13, 1.02, 0.32, 0.97);
            transform: rotate(-90deg);
            width: 20px;
        }
    }

    &__arrow {
        @include fade("in","up", 200px, 1s);
        background-color: rgba($color-7, $alpha: .5);
        position: absolute;
        bottom: 0;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;


        p {
            color: $color-4;
            font-weight: $font-weight-600;
        }

        img {
            width: 40px;
            height: auto;
        }

        &--left {
            cursor: pointer;
            transition: 800ms all cubic-bezier(0.08, 0.96, 0.29, 0.96);
            transform: translateX(0px);


            &:hover {
                transform: translateX(-10px);
            }
        }

        &--right {
            cursor: pointer;
            transition: 800ms all cubic-bezier(0.08, 0.96, 0.29, 0.96);
            transform: translateX(0px);


            &:hover {
                transform: translateX(10px);
            }
        }
    }


    // Banner

    &__mockup {
        
        position: absolute;
        width: 450px;
        height: auto;
        filter:opacity(.9) blur(.75px);
        
        &1 {
            @include fade("in","up", 200px, 3s);
            left: 60%;
            top: 20%;
            rotate: -45deg;

            @include tablet {
                left: 20%;
                top: 30%;
            }
            
            @include mobile {
                top: 40%;
                width: 300px
            }
        }
        
        &2 {
            @include fade("in","right", 200px, 3s);
            width: 100px;
            left: 59%;
            top: 66%;
            rotate: -45deg;

            @include tablet {
                left: 18%;
                top: 72%;
            }

            @include mobile {
                width: 60px;
                top: 80%;
                left: 20%;
            }
        }
        
        &3 {
            @include fade("in","left", 200px, 3s);
            width: 300px;
            left: 80%;
            top: 40%;
            rotate: -45deg;

            @include tablet {
                left: 60%;
                top: 65%;
            }

            @include mobile {
                width: 200px;
                top: 80%;
                left: 60%;
            }
        }
    }

    &__shape1 {
        position: absolute;
        width: 100px;
        height: auto;
        transform: translate(-50%, -50%);

        &-1 {
            left: 50%;
            top: 13%;
        }

        &-2 {
            left: 70%;
            top: 50%;
        }

        &-3 {
            left: 30%;
            top: 80%;
        }
    }

    &__shape2 {
        position: absolute;
        width: 400px;
        height: auto;
        transform: translate(-50%, -50%);

        &-1 {
            left: 20%;
            top: 40%;
        }

        &-2 {
            width: 150px;
            left: 70%;
            top: 80%;
        }

        &-3 {
            left: 30%;
            top: 80%;
        }
    }

    &__shape3 {
        position: absolute;
        width: 200px;
        height: auto;
        transform: translate(-50%, -50%);

        &-1 {
            left: 70%;
            top: 0%;
        }

        &-2 {
            width: 150px;
            left: 70%;
            top: 80%;
        }

        &-3 {
            left: 30%;
            top: 80%;
        }
    }

    &__shape4 {
        position: absolute;
        width: 100px;
        height: auto;
        transform: translate(-50%, -50%);

        &-1 {
            left: 40%;
            top: 90%;
        }

        &-2 {
            width: 150px;
            left: 70%;
            top: 80%;
        }

        &-3 {
            left: 30%;
            top: 80%;
        }
    }

    &__shape5 {
        position: absolute;
        width: 200px;
        height: auto;
        transform: translate(-50%, -50%);

        &-1 {
            left: 95%;
            top: 30%;
        }

        &-2 {
            width: 150px;
            left: 70%;
            top: 80%;
        }

        &-3 {
            left: 30%;
            top: 80%;
        }
    }

    &__shape6 {
        position: absolute;
        width: 400px;
        height: auto;
        transform: translate(-50%, -50%);

        &-1 {
            left: 83%;
            top: 75%;
            transform: rotate(45deg);
        }

        &-2 {
            width: 150px;
            left: 70%;
            top: 80%;
        }

        &-3 {
            left: 30%;
            top: 80%;
        }
    }

}
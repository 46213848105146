@import './../../scss/all.scss';

.footer {
    background-color: $color-7;
    height: auto;
    padding: 10px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        align-items: center;
        justify-content: center;
    }

    p {
        color: $color-1;
        font-size: 12px;
    }

    a {
        font-weight: 600;
        color: $color-2;
    }

}
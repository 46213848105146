@import './../../scss/all.scss';

.contact {
    background-color: $white;
    height: auto;
    padding: 80px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;

    .default-title {
        // opacity: 0;
    }

    .container {
        align-items: center;
        justify-content: space-evenly;
        gap: 40px;

        @include mobile {
            flex-direction: column;
            gap: 0;
        }
    }

    &__modal {
        // opacity: 0;
        background-color: $color-1-500;
        height: 100%;
        min-height: 220px;
        max-height: 100%;
        width: 450px;
        max-width: 70%;
        margin: 40px 0;
        // border: 3px solid $color-2;
        box-shadow:  10px 10px 40px #bebebe;
        border-radius: 6px;
        padding: 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;

        @include mobile {
            margin: 16px 0 40px;
            max-width: 90%;
        }

        h4 {
            font-size: 26px;
            font-weight: 600;
            text-transform: uppercase;
            color: $white;
            text-align: center;
            line-height: 32px;
        }

        p {
            color: $white;
        }
        
        &__action {
            padding: 10px 20px;
            color: $color-1;
            font-weight: 600;
            font-size: 22px;
            border-radius: 4px;
            cursor: pointer;
            width: 150px !important;
            text-align: center !important;
            
            &:hover {
                background-color: $color-2-700 !important;
            }
        }
    }

    &__social {
        display: flex;
        flex-direction: column;
        gap: 20px;

        a {
            padding: 16px 20px;
            border-radius: 50px;
            background-color: #48c55b;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            color: $white;
            font-weight: 700;
            transition: 100ms all ease-in;

            &:hover {
                text-decoration: none;
                background-color: #359b44;
            }
        }

        img {
            width: 30px;
        }
    }

    .social-media {
        width: 20%;
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        gap: 0 !important;
        justify-content: space-around;

        @include mobile {
            width: 100%;
        }

        a {
            max-width: 40%;
            width: auto !important;
            height: auto !important;
            padding: 2px !important;

            @include mobile {
                max-width: 49% !important;
                width: 49% !important;
            }


            &:hover {
                background-color: transparent !important;
            }
        }
    }

    // &.animate {
    //     .default-title {
    //         @include fade("in","right", 300px, 4s);
    //     }
        
    //     .contact__modal {
    //         @include fade("in","zoom", .6, 5s);
    //     }
    // }

}